<template>
  
  <div class="left-sidebar">
    <ul>
      <li><router-link to="fleet-dashboard"><font-awesome-icon icon="home" /></router-link><span class="prompt">Your fleet dashboard</span></li>
      <li><router-link to="fleet-staff"><font-awesome-icon icon="users" /></router-link><span class="prompt">All of your drivers</span></li>
      <li><router-link to="fleet-trucks"><font-awesome-icon icon="truck" /></router-link><span class="prompt">All trucks in this fleet</span></li>
      <!-- <li><router-link to="fleet-all-stats"><font-awesome-icon icon="info-circle" /></router-link><span class="prompt"></span></li> -->
      <li><router-link to="fleet-all-reports"><font-awesome-icon icon="clipboard-list" /></router-link><span class="prompt">Create a Report</span></li>
      <li><router-link to="fleet-sms-revisions"><font-awesome-icon icon="info" /></router-link><span class="prompt">All activity in your fleet</span></li>
      <!-- <li><router-link to="fleet-covid-plans"><font-awesome-icon icon="pen" /></router-link><span class="prompt">All reports</span></li> -->
      <!-- <li><router-link to="fleet-all-uploads"><font-awesome-icon icon="camera" /></router-link><span class="prompt">Upload documents for this fleet</span></li> -->
    </ul>
    
  </div>

</template>


<script>
  
export default {

  data: function() {
    return {

    }
  },

  props: {

  },

  methods: {

    


  }

}



</script>


<style lang="scss">
  


</style>